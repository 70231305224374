export const publicPages = [
    "Home",
    "TermsAndConditions",
    "PrivacyPolicy",
    "Contact",
    "CategoryCustomerEngagement",
    "CategoryBrands",
    "CategoryEvents",
    "CategoryFoodCourts",
    "CategoryEmployeeEngagement",
    "Workflow",
    "GamesCatalog",
    "RewardsPartner",
    "UltimateChallenge",
  ];