export default [
  {
    path: "/:business",
    name: "Register",
    meta: {
      requiresAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: "register" */ "@/views/Miror/Register.vue"),
  },
  {
    path: "/:business/miror-auth",
    name: "MirorAuth",
    meta: {
      requiresAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: "mirorauth" */ "@/views/Miror/Auth.vue"),
  },
  {
    path: "/:business/lobby",
    name: "Lobby",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "lobby" */ "@/views/Miror/Lobby.vue"),
  },
  {
    path: "/:business/game/:schedule_id/:game/:redirect?/:game_session_id?",
    name: "Game",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "game" */ "@/views/Miror/Game.vue"),
  },
  {
    path: "/:business/leaderboard/:schedule_id",
    name: "Leaderboard",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "leaderboard" */ "@/views/Miror/Leaderboard.vue"
      ),
  },
  {
    path: "/:business/rewards",
    name: "Rewards",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "rewards" */ "@/views/Miror/Rewards.vue"),
  },
];
