import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";

export default {
  state: {
    sid: null,
    notifications: [],
  },
  getters: {},
  mutations: {
    setSid(state, payload) {
      state.sid = payload;
    },
  },
  actions: {
    setSid({ commit }, params) {
      ApiService.post(apiResource.saveSubscriptionId, params).then(() => {
        commit("setSid", params.sid);
      });
    },
  },
};
