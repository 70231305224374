<template>
  <div>
    <img :src="require('@/assets/images/404.svg')" class="absolute bottom-0" />
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style scoped></style>
