import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import TokenService from "@/common/token.service";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  state: {
    user: null,
    token: null,
    isAuthenticated: !!TokenService.getToken("token"),
    businessSlug: TokenService.getToken("business_slug"),
    businessName: TokenService.getToken("business_name"),
    businessLocation: TokenService.getToken("business_location"),
    businessCategory: TokenService.getToken("business_category"),
    businessLogo: TokenService.getToken("business_logo"),
    maintenanceError: null,
    userSrc: !!TokenService.getToken("usersrc"),
  },
  getters: {
    user(state) {
      return state.user;
    },
    token(state) {
      return {
        token: state.token,
        refreshToken: state.refreshToken,
      };
    },
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    businessSlug(state) {
      return state.businessSlug;
    },
    businessName(state) {
      return state.businessName;
    },
    businessLocation(state) {
      return state.businessLocation;
    },
    businessCategory(state) {
      return state.businessCategory;
    },
    businessLogo(state) {
      return state.businessLogo;
    },
    maintenanceError(state) {
      return state.maintenanceError;
    },
  },
  mutations: {
    setAuth(state, data) {
      state.user = data.payload;
      state.token = data.token;
      state.isAuthenticated = true;

      TokenService.saveToken("token", state.token);

    },
    purgeAuth(state) {
      state.user = null;
      state.token = null;
      state.isAuthenticated = false;

      TokenService.destroyToken("token");
      TokenService.destroyToken("mobile");
      TokenService.destroyToken("name");
    },
    setMaintenanceError(state, data) {
      state.maintenanceError = data;
    },
    setBusinessSlug(state, data) {
      state.businessSlug = data;
    },
    setBusinessName(state, data) {
      state.businessName = data;
    },
    setBusinessLocation(state, data) {
      state.businessLocation = data;
    },
    setBusinessCategory(state, data) {
      state.businessCategory = data;
    },
    setBusinessLogo(state, data) {
      state.businessLogo = data;
    },
  },
  actions: {
    checkAuth({ commit, dispatch, rootGetters }) {
      if (TokenService.getToken("token")) {
        ApiService.setHeader();
        // API Call to get user details without Login
        if (!rootGetters.profile) {
          dispatch("getProfile");
        }
      } else {
        commit("purgeAuth");
      }
    },
    validateOTP({ commit }, params) {
      return new Promise((resolve, reject) => {
        ApiService.post(apiResource.validateOTP, params)
          .then(({ data }) => {
            commit("setAuth", data.data);
            resolve(data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    loginMirorUser({ commit }, params) {
      return new Promise((resolve, reject) => {
        ApiService.simplePost(apiResource.miror.login, params)
          .then(({ data }) => {
            commit("setAuth", data.data);
            resolve(data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    signOut({ commit }) {
      commit("purgeAuth");
    },

    signOutLocal({ commit }) {
      commit("purgeAuth");
    },

    setError({ commit }, payload) {
      commit("setMaintenanceError", payload);
      toast.error(payload, { timeout: false });
    },
  },
};