<template>
  <div
    class="miror-loader w-screen fixed top-0 left-0 flex justify-center items-center z-50"
    v-if="businessSlug === mirorSlug"
  >
    <svg
      width="120"
      height="30"
      viewBox="0 0 120 30"
      xmlns="http://www.w3.org/2000/svg"
      fill="#000"
    >
      <circle cx="15" cy="15" r="15">
        <animate
          attributeName="r"
          from="15"
          to="15"
          begin="0s"
          dur="0.8s"
          values="15;9;15"
          calcMode="linear"
          repeatCount="indefinite"
        />
        <animate
          attributeName="fill-opacity"
          from="1"
          to="1"
          begin="0s"
          dur="0.8s"
          values="1;.5;1"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx="60" cy="15" r="9" fill-opacity="0.3">
        <animate
          attributeName="r"
          from="9"
          to="9"
          begin="0s"
          dur="0.8s"
          values="9;15;9"
          calcMode="linear"
          repeatCount="indefinite"
        />
        <animate
          attributeName="fill-opacity"
          from="0.5"
          to="0.5"
          begin="0s"
          dur="0.8s"
          values=".5;1;.5"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx="105" cy="15" r="15">
        <animate
          attributeName="r"
          from="15"
          to="15"
          begin="0s"
          dur="0.8s"
          values="15;9;15"
          calcMode="linear"
          repeatCount="indefinite"
        />
        <animate
          attributeName="fill-opacity"
          from="1"
          to="1"
          begin="0s"
          dur="0.8s"
          values="1;.5;1"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  </div>
  <div
    class="loader w-screen fixed top-0 left-0 flex justify-center items-center z-50"
    v-else
  >
    <img :src="require('@/assets/images/loader.gif')" class="w-48 h-48" />
  </div>
</template>

<script>
import TokenService from "@/common/token.service";

export default {
  name: "Page Loader",
  data() {
    return {
      businessSlug: TokenService.getToken("business_slug"),
      mirorSlug: process.env.VUE_APP_BUSINESS_SLUG_MIROR,
    };
  },
};
</script>

<style scoped lang="postcss">
.loader {
  @apply bg-nColorYellow;
  height: 100dvh;
}
.miror-loader {
  @apply bg-white;
  height: 100dvh;
}
</style>
