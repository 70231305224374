<template>
    <div>
        <div v-if="publicPages.includes($route.name)">
            <Loader v-if="isPageLoading" />
            <router-view v-else :key="$route.fullPath" />
        </div>
        <!-- <div v-else-if="isLandscape && !hasIframeContent"> -->
        <div v-else-if="isLandscape">
            <div class="hidden md:grid md:place-items-center text-white">
                <img :src="logo" class="w-1/6 pt-8" />
                <lottie-animation
                    path="lotties/mobile-only.json"
                    :loop="true"
                    :autoPlay="true"
                    :speed="1"
                    :height="420"
                />
                <span class="text-center font-ZuumeRegular">
                    <p class="text-4xl">Oops!</p>
                    <p class="text-2xl">
                        This site is available only for Mobile Browsers
                    </p>
                </span>
                <!-- <img :src="require('@/assets/images/mobile-only.svg')" class="my-4" /> -->
            </div>
            <div class="w-full grid md:hidden gap-2">
                <p class="text-lg text-white text-center font-bold mt-10">
                    Rotate your phone
                </p>
                <div class="place-self-center">
                    <lottie-animation
                        path="lotties/rotate-portrait.json"
                        :loop="true"
                        :autoPlay="true"
                        :speed="0.5"
                        :width="200"
                        :height="200"
                    />
                </div>
                <p class="text-sm text-white text-center">
                    Please use portrait mode.
                </p>
            </div>
        </div>
        <div v-else>
            <div>
                <Loader v-if="isPageLoading" />
                <router-view v-else :key="$route.fullPath" />
            </div>
        </div>
    </div>
</template>
<script>
import Loader from "@/components/elements/Loader.vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import { mapGetters } from "vuex";
import { publicPages } from "@/common/publicPages.js";
// import { ref, provide, onMounted, onUnmounted } from "vue";

export default {
    // setup() {
    //   const hasIframeContent = ref(false);

    //   provide("hasIframeContent", hasIframeContent);

    //   const handleOrientationChange = (event) => {
    //     console.log(`Orientation changed to ${screen.orientation.type}`);
    //     event.preventDefault();
    //     // Add logic here to react to orientation change if necessary
    //   };

    //   onMounted(() => {
    //     window.addEventListener("orientationchange", handleOrientationChange);
    //   });

    //   onUnmounted(() => {
    //     window.removeEventListener("orientationchange", handleOrientationChange);
    //   });

    //   return {
    //     hasIframeContent,
    //   };
    // },
    components: {
        Loader,
        LottieAnimation,
    },
    data() {
        return {
            logo: this.$store.getters.businessLogo,
            isLandscape: false,
            publicPages,
        };
    },
    created() {},
    mounted() {
        this.checkLandscape();
        window.addEventListener("resize", this.checkLandscape);
        // setTimeout(() => {
        //   var webPushrBell = document.getElementById("webpushr-bell-optin");
        //   var webPushrPrompt = document.getElementById("webpushr-prompt-wrapper");
        //   if (this.$route.name == "Lobby") {
        //     if (webPushrBell) {
        //       webPushrBell.style.display = "block";
        //     }
        //     if (webPushrPrompt) {
        //       webPushrPrompt.style.display = "block";
        //     }
        //   } else {
        //     if (webPushrBell) {
        //       webPushrBell.style.display = "none";
        //     }
        //     if (webPushrPrompt) {
        //       webPushrPrompt.style.display = "none";
        //     }
        //   }
        // }, 500);
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.checkLandscape);
    },
    methods: {
        checkLandscape() {
            this.isLandscape = window.matchMedia(
                "(orientation: landscape)",
            ).matches;
        },
    },
    computed: { ...mapGetters(["isPageLoading"]) },
    watch: {},
};
</script>
<style>
body,
html {
    background-color: #000000;
}
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
</style>
